<template>
  <div id="sync-strava" class="box container">
    <p v-if="errorMessage" class="has-text-danger">{{ $t(errorMessage) }}</p>
  </div>
</template>

<script>

export default{
  name: 'SyncStrava',
  data(){
    return {
      errorMessage: ''
    };
  },
  mounted: function(){
    var redirectLink = localStorage.getItem('redirectSyncLink') || '/settings';
    console.log(redirectLink);
    this.post('/strava/sync', { code: this.$route.query.code }).then(() => {
      this.$router.push(redirectLink);
      this.$store.dispatch('reloadUserDataAfterSync');
    }).catch(err => {
      console.error(err);
      this.errorMessage = err;
      setTimeout(() => { this.$router.push(redirectLink); }, 2000);
    });
  },
}
</script>

<style lang="scss" scoped>
</style>
